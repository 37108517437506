<template>
  <div>
    <h1> {{title}} </h1><br>
    <input type="text" ref="name">
    <button @click="hclick">Click Me</button>
    <button @click="showModalOne">Show Modal 1</button>  
    <button @click="showModalTwo">Show Modal 2</button>  

    <teleport to=".modals" v-if="showModal1 === true">
      <Modal :header="header" :text="text" @hideModal="hideModalOne" />
    </teleport>
    <teleport to=".modals" v-if="showModal2 === true">
      <ModalInfo :info="infoTitle" :desc="infoDesc" @hideModal2="hideModalTwo" />
    </teleport>
  </div>
</template>


<script>
import Modal from './components/Modal.vue'
import ModalInfo from './components/ModalInfo.vue'

export default {
  name: 'App',
  data(){
    return{
      title: 'My First CLI Vue App',
      header: 'Sign up for the Giveaway!',
      text: 'Grab your ninja swag for half price!',
      infoTitle: 'Welcome to Second Modal',
      infoDesc: 'This is the second modal that we created.',
      showModal1: false,
      showModal2: false
    }
  },
  methods:{
    hclick(){
      console.log(this.$refs.name)
      this.$refs.name.classList.add('active');
      this.$refs.name.focus()
    },
    showModalOne(){
      this.showModal1 = true
    },
    hideModalOne(){
      this.showModal1 = false
    },
    showModalTwo(){
      this.showModal2 = true
    },
    hideModalTwo(){
      this.showModal2 = false
    }
  },
  components:{Modal, ModalInfo},
  
}
</script>

<style>
#app, .modals {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
h1{
  border-bottom: 1px solid #ddd;
  display: inline-block;
  padding-bottom: 10px;
  color: aqua;
}
</style>
