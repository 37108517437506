<template>
    <div class="backdrop">
        <div class="modal">
            <h1>{{info}}</h1>
            <p>{{desc}}</p><br><br>
            <button @click="closeModal">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    props:['info', 'desc'],
    methods:{
        closeModal(){
            this.$emit('hideModal2')
        }
    }
    // data(){
    //     return{
    //         header: 'Sign up for the Giveaway!',
    //         text: 'Grab your ninja swag for half price!'

    //     }
    // }

}
</script>

<style scoped>
    .modal{
        width: 600px;
        padding: 20px;
        margin: 100px auto;
        background: white;
        border-radius: 10px;
    }
    .backdrop{
        top: 0;
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
    }
    .modal h1{
        color: pink;
        font-weight: bold;
        border: none;
        padding: 0
    }
    h1 {
        color: #03cfb4;
        border: none;
        padding: 0;
    }
    .modal p{
        font-style: normal;
    }
</style>